.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

span{
  white-space: pre-line;
}

a {
  color: inherit;
  text-decoration: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}











.philosophySectionSubContainer {
  height: fit-content;
}




.card1Container {
  background-size: cover;
  background-image: url("./components/images/card1.png");
  border-radius: 16px;
  text-align: left;
}

.card2Container {
  background-size: cover;
  background-image: url("./components/images/card2.png");
  border-radius: 16px;
  text-align: left;


}

.circleMenuContainer {
  text-align: center;
}

.circleMenu {
  cursor: pointer;
}

.marginAll {
  margin: 20px;

}

.alignItemsCenter {
  align-items: center;
}

.justifyContentCenter {
  justify-content: center;
}

.displayFlex {
  display: flex;
}

.whiteSpacePreLine {
  white-space: pre-line;
}

.flexRightAlign {
  margin-left: auto;
}

.flexLeftAlign {
  margin-right: auto;
}

.flexBottomAlign {
  margin-top: auto;
}

.blur {
  backdrop-filter: blur(6px);
}

.headerLogoImg {
  height: 70px;
  margin-top: -4px;
}

.marginLeft4 {
  margin-left: 4px;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginRight4 {
  margin-right: 4px;
}

.marginRight50 {
  margin-right: 50px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop28 {
  margin-top: 28px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop32 {
  margin-top: 32px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginTop60 {
  margin-top: 60px;
}

.marginTop48 {
  margin-top: 48px;
}

.marginTop75 {
  margin-top: 75px;
}

.marginTop80 {
  margin-top: 80px;
}

.marginTop90 {
  margin-top: 90px;
}

.marginTop120 {
  margin-top: 120px;
}

.paddingTop10 {
  padding-top: 10px;
}

.paddingLeft8 {
  padding-left: 8px;
}


.paddingLeft20 {
  padding-left: 20px;
}
.paddingRight15 {
  padding-right: 15px;
}




.cursorPointer {
  cursor: pointer;
}

.heightMatch {
  height: 100%;
}

.height120 {
  height: 120px;
}

.height187 {
  height: 187px;
}

.height250 {
  height: 250px;
}

.height204 {
  height: 204px;
}

.height720 {
  height: 720px;
}

.height1080 {
  height: 1000px;
}



.backGroundBlue {
  background-color: blue;
}

.backGroundRed {
  background-color: red;


}

.backGroundBlack {
  background-color: #030710;
}



.flexWrapWrap {
  flex-wrap: wrap;
}

.fontWeightBold {
  font-weight: bold;
}



.childFlex1 {
  flex: 1;
}


.widthMatch {
  width: 100%;
}

.width1280 {
  width: 1280px;
}

.width692 {
  width: 692px;
}

.mainMRPLogoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.mainSectionContainer {
  position: relative;
  height: 100%;
  background-color: black;
}


.locationAllSubContainer {
  display: flex;
  height: 100%;
}

.flexDisplay {
  display: flex;
}


/* 모바일 */
@media only screen and (max-width: 767px) {
  #mbEnLargeIcon{
    position: absolute;
    right: 0;
    margin-right: 10px;
    margin-top: 10px;
    z-index: 0;
  }
  .mbEnLargeBox{
    position: relative;
    z-index: 0;
  }
  .strategyImageBox{
    height: 360px;
    display: flex;
    align-items: center;
    text-align: left;
    margin-right: auto;
  }
  .philosophyContainer{
    margin-top: 60px;
    width: 100%;    
    padding-top: 20px;
    height: 240px;
  }

  .slider {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease-in-out;
    width: 100%;
  }
  
  .slide.active {
    opacity: 1;

  }
  
  .editStateModalOverlay {
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .editStateModal {
    position: fixed;
    overflow: auto;
    border-radius: 5px;
    outline: none;
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  .fullScreenContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #closeIconImg{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 25px;
    z-index: 1;
  }
  .fullScreenSubContainer{
    width: 100%;
    height: 100vh;
    background-color: black;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .fullScreenImage {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
  
  }
  .mbMrpHoldingsGraphContainer{
    margin-top: 40px;
    width: 100%;
    height: 479px;
    background:#000000;
    border: 1px solid #333333;
    display: flex;
    align-items: center;
  }
  .mbMrpHoldingsGraphSubContainer{
    height: 100%;
  }
  .asdf{
    background-color: blue;
  }
    .mrpHoldingsGraph {
    width: 100%;
  }


  .organizationMenuDropBoxContainer{
    margin-top: 51px;
  }

  .organizationMenuDropBoxSubContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .organizationMenuDropBoxHeader{
    /* gray 800 */
    background: #333333;
    /* grey 400 */
    border: 1px solid #929292;
    border-radius: 5px;
    width: 180px;
    height: 37px;
    align-items: center;
    display: flex;
    padding: 0px 10px 0px 10px;
    color: #FDFDFD;
    font-weight: 700;
    padding-top: 2px;
    justify-content: space-between;

  }
  .organizationMenuDropBoxItemContainer{
    width: 100%;
    position: absolute;
    z-index: 1;
  }
  .organizationMenuDropBoxItemSubContainer{
    width: 200px;
    margin-left: auto;
    margin-top: 10px;
    background: #333333;
    border: 1px solid #595959;
    border-radius: 5px;
    margin-right: 50px;
    z-index: 1;
  }



  .organizationMenuDropBoxItem{
    height: 37px;
    display: flex;
    align-items: center;
    padding: 0px 10px 0px 10px;
    color: #929292;
    font-size: 12px;
    font-weight: 400;

  }
  .organizationMenuDropBoxItem:hover{
    background: #595959;
    font-weight: 400;
    font-size: 12px;
    color: #FDFDFD;
  }

  #organizationMenuTitleText{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    color: #E6E6E6;
  }

  .organizationSubContainer{
    padding: 0px 25px;
    width: 100%;
  }
  .menuCloseBox{
    text-align: right;
  }
  .menuLogoBox{
    margin-top: 40px;
  }
  .menuLogoBox img{
    width: 80px;
    margin-left: -15px;
  }
  .menuFont {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 20px;
    color: #929292;
  }
  
  .menuFont:hover {
    color: #000000;
    font-weight: 700;
  }

  .menuContainer {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100vh;
    z-index: 10;
    transition: right 0.3s ease-in-out;
    background: rgba(253, 253, 253, 0.82);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(6px);
  }
  
  .menuSubContainer{
    padding: 20px 25px;
    height: 100%;
  }
  .menuContainer.show {
    right: 0;
  }

  .headerMenu{
    height: 50px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
  }
  
  .headerComponentAllContainer.hidden {
    transition: transform 0.5s ease-in-out;
    transform: translateY(-100%);
  }
  
  .headerComponentAllContainer.visible {
    transition: transform 0.5s ease-in-out;
    transform: translateY(0);
  }
  

  .mapContainer {
    position: relative;
    height: 430px;
    width: 100%;
    margin: 0px 25px;
  }
  .contentContainer{
    width: 100%;
  }
  .locationSubContainer{
    display: flex;
    padding: 0px 25px;    
  }
  .contactUsInfoBox{
    min-width: 375px;
    width: 100%;
  }
  .locationInfoText{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #E6E6E6;
  }

  .locationContainer{
    margin-top: 25px;
    min-width: 375px;
    width: 100%;
  }
 
  .footerText{
    color: #929292;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .footerContainer{
    padding-left: 25px;
  }

  .locationAllContainer {
    background-color: #000000;
    min-height: 100%;
    
  }

  .organizationContainer {
    background-color: #030710;
    height: 100%;
  }
  .cardContainer{
    margin-top: 48px;
    display: flex;
    height: 360px;
  }
  
  .strategyMoreBtn{
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px 0px
  }
  .strategySectionSubContainer {
    width: 100%;
    padding: 0px 25px;
  }
  #strategyLogoIcon {
    height: 36px;
  }
  
  .strategySectionContainer {
    background-size: cover;
    height: 100%;
    background-color: #000000;
  }
  .philosophyText {
    font-size: 20px;
  }
  .philosophySectionContainer {
    background-size: cover;
    background-image: url("./components/images/philosophy_by.png");
    height: 100%;
  }

  .allContainer {
    min-width: 375px;
  }


  .headerComponentAllContainer {
    display: flex;
    background: rgba(253, 253, 253, 0.82);
    position: fixed;
    z-index: 5;
    width: 100%;
  }
  .headerContainer {
    height: 60px;
    width: 100%;
    max-width: 100vw;
    align-items: center;
  }

  .menuBtn {
    border: none;
    background-color: transparent;
    margin-left: auto;
  }
  .menuBtnBox{
    width: 100%;
    display: flex;
  }

  .mainVideoContainer {
    z-index: 0;
    min-width: 100%
  }




  .phliosophyIcon {
    width: 160px;
  }
  
}

/* 데스크탑 */
@media only screen and (min-width: 768px) {

  .organizationSubContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menuFont {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 14px;
    color: #929292;
  }
  
  .menuFont:hover {
    color: #000000;
    font-weight: 700;
  }

  .mapContainer {
    position: relative;
    height: 430px;
    width: 1280px;
  }
  .locationSubContainer{
    display: flex;
    justify-content: center;
  }
  .locationInfoText{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FDFDFD;
  }

  .locationContainer {
    height: 202px;
    background-size: cover;
    background-image: url("./components/images/locationBackGround.png")
  }
  .contactUsInfoBox{
    width: 1280px;
    margin-top: 32px;
    align-items: center;
  }

  
  .footerText{
    color: #FDFDFD;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  .locationAllContainer {
    background-color: #030710;
    min-height: 720px;
  }

  .organizationContainer {
    background-color: #030710;
    min-height: 720px;
  }
  .cardContainer {
    margin-top: 120px;
  }

  .strategySectionSubContainer {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  #strategyLogoIcon {
    height: 90px;
  }
  
  .strategySectionContainer {
    background-size: cover;
    min-height: 720px;
    background-image: url("./components/images/mprPartnersBackground.png");
  }
  
  .philosophyText {
    font-size: 36px;
  }
  .philosophySectionContainer {
    background-size: cover;
    background-image: url("./components/images/philosophy_by.png");
    min-height: 720px;
    min-width: 1280px;
  }

  .headerContainer {
    width: 1280px;
    height: 60px;
    justify-content: space-between;
  }

  .allContainer {
    min-width: 960px;
  }

  .mrpHoldingsGraph {
    margin-top: 40px;
    height: 100%;
    min-height: 460px;
    max-height: 520px;
  }

  .headerComponentAllContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(253, 253, 253, 0.82);
    position: fixed;
    width: 100%;
    z-index: 5;
    min-width: 1280px;
  }

  .mainVideoContainer {
    z-index: 0;
    min-width: 1280px
  }
  .phliosophyIcon {
    width: 1280px;
  }
  

}


.blackFilter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #030710;
  opacity: 0.2;
  z-index: 1;
}

.scrollDownContainer {
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  cursor: pointer;
  z-index: 5;
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.fontNotoSansNormal {
  font-family: 'Noto Sans KR', sans-serif;
}



.fontNotoSansBold {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
}

.whiteTextColor {
  color: #fff
}

.fontSize12 {
  font-size: 12px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize36 {
  font-size: 20px;
}

.whiteSpacePreLine {
  white-space: pre-line;
}

.greyBar {
  width: 4px;
  height: 16px;
  background-color: #E6E6E6;
  border-radius: 1px;
}

.locationImage {
  height: 30px;
}

.blueCircleContainer {
  width: 130px;
  height: 130px;
  background-color: #2c55c7;
  border-radius: 100px;
}

.phoneNumberContainer {
  width: 140px;
  border-right: #fff 1px solid;
}


.custom_typecontrol {
  position: absolute;
  display: block;
  bottom: 100px;
  right: 10px;
  z-index: 1;
  cursor: pointer;

}


#map {
  width: 100%;
  height: 100%;
}




/* 맥북 크기 */
@media only screen and (min-width: 1400px) {


  .cardContainer {
    margin-top: 60px;
  }




  .footerContainer {
    height: 100px;
  }
}

/* 일반 모니터 */
@media only screen and (min-width: 2000px) {
  .philosophyText {
    font-size: 40px;
  }

  .fullpage-wrapper {
    position: relative;
    height: 100vh;
  }

  .footer {
    position: fixed;
    bottom: 0;
    height: 200px;
    width: 100%;
    background-color: #ccc;
  }

  .asd {
    width: 100%;
  }



  .organizationButtonMenuContainer {
    margin-top: 80px;
  }
}

.organiztionCircleMenuButton {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  border: 1px #fff solid;
  text-align: center;
}

.zoomContainer {
  width: 25px;
  height: 25px;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #c5c5c5;
  display: flex;
}

#philosophyIcon {
  height: 100px;
}


#numberIcon {
  height: 35px;
}

#downIcon {
  height: 15px;
}



/* .trapezoid {
  width: 150px;
  height: 100px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  border-radius: 10px;
  background-color: red;
  text-align: center;
  line-height: 80px;
} */
.trapezoid {
  width: 120px;
  border-bottom: 80px solid orange;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  height: 0;
  text-align: center;
  line-height: 80px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}